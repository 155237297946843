import React from 'react'
import { rhythm } from '../../utils/typography'

export const PostTitle = ({ title }) => (
  <h1
    style={{
      backgroundColor: '#f9f9f9',
      margin: `0 -${rhythm(0)}`,
      padding: `${rhythm(0.8)} `,
      borderBottom: '0.5px solid #e0e0e0',
    }}
  >
    {title}
  </h1>
)
